import React, { useEffect, useState } from 'react';
import { Container, Table, Button } from "react-bootstrap";
import axios from 'axios';
import useAuth from '../../Hooks/useAuth';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../../css/releases-page.css';

const RELEASES_API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
const cognitoAdminGroup = process.env.REACT_APP_COGNITO_ADMIN_GROUP;

function ReleasesPage() {
    const [data, setData] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const { tokens, loading } = useAuth();
    const { accessToken: { payload: { "cognito:groups": groups = [] } = {} } = {} } = tokens || {};
    const isAdmin = [cognitoAdminGroup].some(group => groups.includes(group));

    const axiosInstance = axios.create({
        baseURL: RELEASES_API_ENDPOINT,
        headers: {
            'Authorization': `Bearer ${tokens.accessToken}`
        },
    });

    useEffect(() => {
        const fetchData = async () => {
            if (loading) return;

            try {
                const response = await axiosInstance.get('/releases');
                let responseDataSorted = response.data.sort((a, b) => a.repository.localeCompare(b.repository))
                setData(responseDataSorted);
            }
            catch (error) {
                console.error('Error fetching data', error);
            }
        };

        fetchData();
    }, [loading, tokens, axiosInstance]);

    const handleButtonClick = async () => {
        setIsLoading(true);
        try {
            const response = await axiosInstance.post('/releases');
            console.log(response)
        } catch (err) {
            console.error(err)
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <Container className="text-center">
            <h2 className="table-title">Releases</h2>
            <Table striped bordered hover size="sm">
                <thead>
                    <tr>
                        <th>#</th>
                        <th>Repository</th>
                        <th>SIT</th>
                        <th>PPRD</th>
                        <th>PROD</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        data.map((release, index) => (
                            <tr key={index}>
                                <td>{index + 1}</td>
                                <td>
                                    {release.projectLink ? (
                                        <a href={release.projectLink} target="_blank" rel="noopener noreferrer">
                                            {release.repository}
                                        </a>
                                    ) : (
                                        <span>{release.repository}</span>
                                    )}
                                </td>
                                <td>{release.sit}</td>
                                <td>
                                    {release.projectLink ? (
                                        <a 
                                            href={`${release.projectLink}/commits/${release.pprd}`} 
                                            target="_blank" 
                                            rel="noopener noreferrer"
                                        >
                                            {release.pprd}
                                        </a>
                                    ) : (
                                        release.pprd
                                    )}
                                </td>
                                <td>{release.prod}</td>
                            </tr>
                        ))
                    }
                </tbody>
            </Table>
            {
                isAdmin && (
                    <div className="mb-2 d-flex justify-content-start">
                        <Button onClick={handleButtonClick} disabled={isLoading} variant="primary">
                            {isLoading ? 'Updating...' : 'Update releases'}
                        </Button>
                    </div>
                )
            }
        </Container>
    )
}

export default ReleasesPage;